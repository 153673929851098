exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-accessories-js": () => import("./../../../src/pages/components/accessories.js" /* webpackChunkName: "component---src-pages-components-accessories-js" */),
  "component---src-pages-components-bike-js": () => import("./../../../src/pages/components/bike.js" /* webpackChunkName: "component---src-pages-components-bike-js" */),
  "component---src-pages-components-convictions-js": () => import("./../../../src/pages/components/convictions.js" /* webpackChunkName: "component---src-pages-components-convictions-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-header-js": () => import("./../../../src/pages/components/header.js" /* webpackChunkName: "component---src-pages-components-header-js" */),
  "component---src-pages-components-infringements-js": () => import("./../../../src/pages/components/infringements.js" /* webpackChunkName: "component---src-pages-components-infringements-js" */),
  "component---src-pages-components-quote-js": () => import("./../../../src/pages/components/quote.js" /* webpackChunkName: "component---src-pages-components-quote-js" */),
  "component---src-pages-components-test-js": () => import("./../../../src/pages/components/test.js" /* webpackChunkName: "component---src-pages-components-test-js" */),
  "component---src-pages-components-theft-accidents-js": () => import("./../../../src/pages/components/theftAccidents.js" /* webpackChunkName: "component---src-pages-components-theft-accidents-js" */),
  "component---src-pages-components-tnc-js": () => import("./../../../src/pages/components/tnc.js" /* webpackChunkName: "component---src-pages-components-tnc-js" */),
  "component---src-pages-components-year-month-js": () => import("./../../../src/pages/components/yearMonth.js" /* webpackChunkName: "component---src-pages-components-year-month-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

